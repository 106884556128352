<template>
  <body>
    <div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
      data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">

      <div class="dashboard-wrapper page-wrapper">
        <div class="page-breadcrumb">
          <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <h4 class="page-title">Create Company</h4>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-xlg-9 col-md-12">
              <ValidationObserver ref="simpleRules">
                <form class="form-horizontal form-material" v-on:submit.prevent="validationForm">
                  <div class="card profile-card">
                    <div class="card-body">
                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Subscription</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Subscription" rules="required" #default="{ errors }">
                            <!-- <multiselect v-model="subscription" track-by="id" :options="subscriptions"
                              :multiple="true"></multiselect> -->
                            <multiselect v-model="subscription" label="name" track-by="id" :multiple="true"
                              placeholder="Select Subscription" :close-on-select="true" :options="subscriptions">
                            </multiselect>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Category</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Category" rules="required" #default="{ errors }">
                            <!-- <multiselect v-model="category" :options="categories.map(item => item.name)"
                              :multiple="true"></multiselect> -->
                            <multiselect v-model="category" label="name" track-by="id" :multiple="true"
                              placeholder="Select Category" :close-on-select="true" :options="categories"></multiselect>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Company Name</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Company Name" rules="required|max:50" #default="{ errors }">
                            <b-input-group class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="BriefcaseIcon" />
                              </b-input-group-prepend>
                              <b-form-input id="companyName" v-model="name" :state="errors.length > 0 ? false : null"
                                placeholder="Company Name" />
                            </b-input-group>
                            <!-- <input type="text" v-model.trim="name"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"> -->
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Email</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Email" rules="required|email|max:50" #default="{ errors }">
                            <b-input-group class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="MailIcon" />
                              </b-input-group-prepend>
                              <b-form-input id="fh-email" v-model="email" type="email"
                                :state="errors.length > 0 ? false : null" placeholder="Email" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">About</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="About" rules="required|max:5000" #default="{ errors }">
                            <b-input-group class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="FileTextIcon" />
                              </b-input-group-prepend>
                              <b-form-textarea id="fh-email" v-model="about" :state="errors.length > 0 ? false : null"
                                placeholder="About" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <!-- Logo Preview -->

                      <b-col cols="12">
                        <b-img thumbnail fluid :src="previewLogoImg" :class="previewLogoImg.length <= 0 ? 'd-none' : null"
                          alt="Image"></b-img>
                      </b-col>

                      <!-- new code to Image (Logo) fire base -->
                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Company Logo</label>
                        <div class="col-md-12 border-bottom p-0">
                          <div class="formcta_wrapper">
                            <div>
                              <validation-provider name="Company Logo" rules="required|image|size:100"
                                #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                  <b-input-group-prepend is-text>
                                    <feather-icon icon="ImageIcon" />
                                  </b-input-group-prepend>
                                  <b-form-file v-model="comLogoImg" ref="comLogInput" @change="onComLogoChange"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- end logo -->

                      <!-- Background Preview -->

                      <b-col cols="12">
                        <b-img thumbnail fluid :src="previewBackImg" :class="previewBackImg.length <= 0 ? 'd-none' : null"
                          alt="Image"></b-img>
                      </b-col>

                      <!-- company Background image firebase -->
                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Company Backgound Image</label>
                        <div class="col-md-12 border-bottom p-0">
                          <div class="formcta_wrapper">
                            <div>
                              <validation-provider name="Company Backgound Logo" rules="required|image|size:100"
                                #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                  <b-input-group-prepend is-text>
                                    <feather-icon icon="ImageIcon" />
                                  </b-input-group-prepend>
                                  <b-form-file v-model="comBackgroundImg" ref="combackInput" @change="onComBackChange"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- End company Background image firebase -->

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Location</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Location" rules="required|max:500" #default="{ errors }">
                            <b-input-group class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="BriefcaseIcon" />
                              </b-input-group-prepend>
                              <b-form-input id="location" v-model="location" :state="errors.length > 0 ? false : null"
                                placeholder="Location" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Country</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Country" rules="required" #default="{ errors }">
                            <multiselect v-model="country" label="name" track-by="id" :multiple="false"
                              placeholder="Select Country" :close-on-select="true" :options="countries"></multiselect>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Postcode</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Postcode" rules="required|max:20" #default="{ errors }">
                            <b-input-group class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="BriefcaseIcon" />
                              </b-input-group-prepend>
                              <b-form-input id="postcode" v-model="postcode" :state="errors.length > 0 ? false : null"
                                placeholder="Postcode" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="main">
                        <div class="flex">
                          <!-- Map Display here -->
                          <div class="map-holder">
                            <div id="map"></div>
                          </div>

                          <!-- Coordinates Display here -->
                          <div class="dislpay-arena">
                            <div class="coordinates-header">
                              <h3>Current Coordinates</h3>
                              <p>Latitude: {{ center[0] }}</p>
                              <p>Longitude: {{ center[1] }}</p>
                            </div>

                            <div class="coordinates-header">
                              <h3>Current Location</h3>

                              <div class="form-group">
                                <validation-provider name="Location" rules="required|max:200" #default="{ errors }">
                                  <input type="text" class="location-control" :value="locations" readonly />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                <button type="button" class="copy-btn" @click="copyLocation">
                                  Copy
                                </button>
                              </div>

                              <button type="button" :disabled="loading" :class="{ disabled: loading }"
                                class="location-btn" @click="getLocation">
                                Get Location
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Phone No</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Phone No" rules="required|max:20" #default="{ errors }">
                            <b-input-group class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="BriefcaseIcon" />
                              </b-input-group-prepend>
                              <b-form-input id="phoneNum" v-model="phone_no" :state="errors.length > 0 ? false : null"
                                placeholder="Phone No" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Website URL</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Website URL" rules="required|max:200" #default="{ errors }">
                            <b-input-group class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="BriefcaseIcon" />
                              </b-input-group-prepend>
                              <b-form-input id="website" v-model="website" :state="errors.length > 0 ? false : null"
                                placeholder="Website URL" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Outlets included</label>
                        <div class="col-md-12 p-0 collapse-col">
                          <div class="company-add" v-for="(find, index) in finds" :key="find.id">
                            <b-container class="bv-example-row">
                              <b-row class="mt-2">
                                <b-col cols="5">
                                  <validation-provider name="Address" rules="required|max:200" #default="{ errors }">
                                    <b-input-group class="input-group-merge"
                                      :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                      <b-input-group-prepend is-text>
                                        <feather-icon icon="HomeIcon" />
                                      </b-input-group-prepend>
                                      <b-form-input v-model="find.address" :state="errors.length > 0 ? false : null"
                                        placeholder="Enter Address" />
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-col>
                                <b-col cols="5">
                                  <validation-provider name="City" rules="required|max:100" #default="{ errors }">
                                    <b-input-group class="input-group-merge"
                                      :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                      <b-input-group-prepend is-text>
                                        <feather-icon icon="HomeIcon" />
                                      </b-input-group-prepend>
                                      <b-form-input v-model="find.city" :state="errors.length > 0 ? false : null"
                                        placeholder="Enter City" />
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-col>
                                <b-col cols="2">
                                  <b-button class="
                                  delete
                                  btn
                                  button-white
                                  uppercase
                                  fas
                                  fa-times
                                " @click="deleteFind(index)" variant="danger">Delete X</b-button>
                                </b-col>
                              </b-row>
                            </b-container>
                          </div>
                          <b-button id="add" @click="addFind" class="
                            btn
                            add-more
                            button-yellow
                            uppercase
                            fas
                            fa-plus
                            mt-2
                          " variant="success">Add +</b-button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card profile-card">
                    <div class="card-body">
                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Meta Title</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Meta Title" rules="required|max:200" #default="{ errors }">
                            <b-input-group class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="FileTextIcon" />
                              </b-input-group-prepend>
                              <b-form-input v-model.trim="meta_title" :state="errors.length > 0 ? false : null" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>

                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Meta Description</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Meta Description" rules="required|max:200" #default="{ errors }">
                            <b-input-group class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="FileTextIcon" />
                              </b-input-group-prepend>
                              <b-form-textarea v-model.trim="meta_description" :state="errors.length > 0 ? false : null"
                                rows="5" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>

                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Meta Keywords</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider name="Keywords" rules="required|max:200" #default="{ errors }">
                            <b-input-group class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="FileTextIcon" />
                              </b-input-group-prepend>
                              <b-form-input v-model.trim="meta_keywords" :state="errors.length > 0 ? false : null" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>

                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <div class="col-sm-12 pl-0 mt-10">
                          <b-button variant="primary" type="submit">Submit</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>

import mapboxgl from "mapbox-gl";
import axios from "axios";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Vue from 'vue'
import { db, storage } from '@/main'
import firebase from 'firebase';
import store from '@/store'

//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
  required, email, confirmed, password, image, size, unique
} from '@validations'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormDatalist,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BFormFile,
  BFormSelect,
  BImg,
  BContainer
} from 'bootstrap-vue'

import Multiselect from 'vue-multiselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Create',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BImg,
    BContainer,
    ValidationProvider,
    ValidationObserver,
    validate,
    required,
    email,
    confirmed,
    password,
    image,
    size,
    unique,
    Multiselect,

  },
  data: function () {
    return {
      imageData: null,
      picture: null,
      uploadValue: 0,
      comLogoImg: '',
      comBackgroundImg: '',
      uploadValuech: 0,
      loading: false,
      locations: "",
      access_token: "pk.eyJ1IjoiaW50cm9zYnMiLCJhIjoiY2t2MHU4bWE4M2h1OTMwczcxMm03YmM0eSJ9.ocDaQEjuvL3rUd50oaB6Qw",
      center: [0, 0],
      map: {},
      name: '',
      email: '',
      asas: '',
      country_code: '',
      about: '',
      // image: '',
      background_image: '',
      location: '',
      country: [],
      maplocation: '',
      postcode: '',
      phone_no: '',
      lat: '',
      lang: '',
      website: '',
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      previewLogoImg: '',
      previewBackImg: '',
      subscriptions: [],
      category: '',
      category_id: [],
      coordinates: [],
      categories: [],
      subscription: '',
      finds: [],
      countries: [],
      locationCountry: '',
      locationPostcode: '',
    }
  },
  mounted() {
    console.log('config', this.$VUE_APP_API_ENDPOINT);

    this.createMap();
  },
  created: function () {
    this.getSubscriptions();
    this.getCountries();
    this.getCategory();
    this.getCurrentCountry();

  },

  methods: {
    async createMap() {
      try {
        // alert(document.getElementsByClassName("mapboxgl-ctrl-geocoder--input"));

        mapboxgl.accessToken = this.access_token;
        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: this.center,
          zoom: 11,
        });
        let geocoder = new MapboxGeocoder({
          accessToken: this.access_token,
          mapboxgl: mapboxgl,
          marker: false,
        });
        this.map.addControl(geocoder);
        geocoder.on("result", (e) => {
          const marker = new mapboxgl.Marker({
            draggable: true,
            color: "#D80739",
          })
            .setLngLat(e.result.center)
            .addTo(this.map);
          this.center = e.result.center;
          marker.on("dragend", (e) => {
            this.center = Object.values(e.target.getLngLat());
          });
        });
      } catch (err) {
        console.log("map error", err);
      }
    },
    async getLocation() {
      try {
        store.dispatch('app/commitActivateLoader');
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.center[0]},${this.center[1]}.json?access_token=${this.access_token}`
        );
        store.dispatch('app/commitDeactivateLoader');
        let locationCountry = '';
        let locationPostcode = '';
        this.locations = response.data.features[0].place_name;
        this.location = response.data.features[0].place_name;
        console.log("response=> ", response.data.features)
        response.data.features.forEach(function (data, i) {
          if (data.place_type[0] == 'country') {
            console.log("country=> ", data.text)
            // this.country =  data.text;
            locationCountry = data.text;
          }
          if (data.place_type[0] == 'postcode') {
            console.log("postcode=> ", data.text)
            // this.postcode = data.text;
            locationPostcode = data.text;
            // this.locationPostcode.push(data.text);
          }
        });
        if (locationPostcode != "") {
          this.postcode = locationPostcode;
        } else {
          this.postcode = '00000';
        }
        this.locationCountry = locationCountry;
        // console.log("country=> ", locationCountry, "postcode=> ", locationPostcode)
        // console.log("country=> ", this.locationCountry)
      } catch (err) {
        store.dispatch('app/commitDeactivateLoader');
        console.log(err);
      }
    },
    copyLocation() {
      if (this.locations) {
        console.log(this.locations)
        navigator.clipboard.writeText(this.locations);
        // alert("Location Copied")
        this.showNotification('Location Copied Successfully!', 'AlignJustifyIcon', 'Success');
      }
      return;
    },

    showNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    addFind: function () {
      this.finds.push({ address: '', city: '' });
    },
    deleteFind: function (index) {
      console.log(index);
      console.log(this.finds);
      this.finds.splice(index, 1);
    },

    getCountries() {
      this.countries = [];

      db.collection("countries")
        .orderBy('country', 'asc')
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            this.countries.push({
              id: doc.id,
              name: doc.data().country,
              // slug: doc.data().slug,
            });
            //console.log(doc.id, " => ", doc.data());

          });


        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    getCategory() {
      this.categories = [];

      db.collection("categories")
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            this.categories.push({
              id: doc.id,
              name: doc.data().name,

            });
            //console.log(doc.id, " => ", doc.data());

          });


        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

    },

    getSubscriptions() {
      this.subscriptions = [];

      db.collection("subscriptions")
        .get()
        .then((querySnapshot) => {
          console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            this.subscriptions.push({
              id: doc.id,
              name: doc.data().title,
            });
            //console.log(doc.id, " => ", doc.data());

          });

          console.log(this.subscriptions);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },

    validationForm() {

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submit();
        }
      })
    },

    submit: function () {
      console.log(this.country[0], this.country, this.locationCountry)
      if (this.country.name == this.locationCountry ||  this.country[0].name == this.locationCountry) {
        store.dispatch('app/commitActivateLoader');
        if (this.center[0] == "") {
          alert("Please select Location In the map");
          return false;
        }
        // console.log('country from data', this.country[0].id)
        // alert(Math.floor(Math.random() * 10000));
        db.collection('countries').doc(this.country.id ?? this.country[0].id)
          .get().then(snapshot => {
            this.asas = snapshot.data().country;
            this.country_code = snapshot.data().code;
            console.log(snapshot.data())
          }).catch(err => {
            store.dispatch('app/commitDeactivateLoader');
            console.error("Error writing document: ", err);
            this.showNotification('Error writing document!', 'AlertTriangleIcon', 'Danger');
          });
        var date = new Date().toLocaleString();//.toISOString().slice(0, 10);

        this.coordinates.push(this.center[0], this.center[1]);


        const stores =
        {
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [this.center[0], this.center[1]]
          },
          'properties': {
            'phone': this.postcode,
            'company_name': this.name,
            'email': this.email,
            'website': this.website,
            'phone_no': this.phone_no,
            'address': this.location,
            'about': this.about,
            'logo': this.picture,
            'city': this.location,
            'country': this.asas,
            'country_code': this.country_code,
            'postalCode': this.postcode,
          }
        }

        db.collection("companies")
          .add({

            subscription: this.subscription,
            category: this.category,
            name: this.name,
            email: this.email,
            country_code: this.country_code,
            // image: '',
            // background_image: '',
            code: Math.floor(Math.random() * 10000),
            about: this.about,
            location: this.location,
            maplocation: this.locations,
            lat: this.center[0],
            lng: this.center[1],
            country: this.country.id ?? this.country[0].id,
            country_name: this.country.name ?? this.country[0].name,
            coordinates: this.coordinates,
            postcode: this.postcode,
            stores: stores,
            phone_no: this.phone_no,
            website: this.website,
            outlets: this.finds,
            meta_title: this.meta_title,
            meta_description: this.meta_description,
            meta_keywords: this.meta_keywords,
            created: date,
            modified: date,
          }).then((doc) => {
            console.log(doc);
            this.category_id = [];
            this.category.forEach((value, index) => {
              this.category_id.push(value.id);
            });
            db.collection("companies").doc(doc.id).update({ category_id: this.category_id })
            return doc;
          }).then((doc) => {
            // Logo Image save process
            console.log("Company Added Successfully!");
            console.log(doc);
            const filename = this.comLogoImg.name;
            if (filename) {
              const ext = filename.slice(filename.lastIndexOf('.'));
              const storageRef = storage.ref();
              const fileRef = storageRef.child('companies/' + doc.id + "." + ext);
              fileRef.put(this.comLogoImg).then(result => {
                fileRef.getDownloadURL().then(logoimageUrl => {
                  console.log(logoimageUrl);
                  db.collection("companies").doc(doc.id).update({ image: logoimageUrl })
                }).catch((error) => {
                  console.error("Error writing document: ", error);
                  this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
                });
              });
            }
            return doc;
          }).then(doc => {
            // Background Image save process
            const filename = this.comBackgroundImg.name;
            console.log(this.comBackgroundImg);
            if (filename) {
              const ext = filename.slice(filename.lastIndexOf('.'));
              const storageRef = storage.ref();
              const fileRef = storageRef.child('companies/' + doc.id + "." + ext);
              fileRef.put(this.comBackgroundImg).then(result => {
                fileRef.getDownloadURL().then(imageUrl => {
                  console.log(imageUrl);
                  db.collection("companies").doc(doc.id).update({ background_image: imageUrl })
                }).catch((error) => {
                  console.error("Error writing document: ", error);
                  this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
                });
              });
            }
            return doc;
          }).then(doc => {
            // adding cid
            db.collection("companies").doc(doc.id).update({ cid: doc.id });

            const stores =
            {
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': [this.center[0], this.center[1]]
              },
              'properties': {
                'phone': this.postcode,
                'company_name': this.name,
                'email': this.email,
                'website': this.website,
                'phone_no': this.phone_no,
                'cid': doc.id,
                'address': this.location,
                'about': this.about,
                'logo': this.picture,
                'city': this.location,
                'country': this.asas,
                'country_code': this.country_code,
                'postalCode': this.postcode,
              }
            }
            db.collection("companies").doc(doc.id).update({ stores: stores });
            // send email to company with signed url from laravel api
            const url = this.$VUE_APP_API_ENDPOINT + "send-company-email";
            const emailData = {
              companyId: doc.id,
              companyEmail: this.email,
              companyName: this.name,
            }
            axios.post(url, emailData)
              .then(response => {
                // console.log(response);
                // console.log(response.data.status);
                if (response.data.status == "success") {
                  store.dispatch('app/commitDeactivateLoader');
                  this.showNotification('Success, Added Successfully!', 'AlignJustifyIcon', 'Success');
                  this.$router.push('/companies/index');

                } else {
                  store.dispatch('app/commitDeactivateLoader');
                  this.showNotification('Error! Email Failed', 'AlertTriangleIcon', 'Danger');
                  this.$router.push('/companies/index');
                }

              })
              .catch(error => {
                store.dispatch('app/commitDeactivateLoader');
                this.showNotification('Error writing document!', 'AlertTriangleIcon', 'Danger');
                console.log(error);
              });
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
            store.dispatch('app/commitDeactivateLoader');
            this.showNotification('Error writing document!', 'AlertTriangleIcon', 'Danger');
          });
      } else {
        store.dispatch('app/commitDeactivateLoader');
        alert('Please Select Country whcich was you used in Location!!');
      }

    },

    onComLogoChange(event) {
      validate(event.target.files[0], 'required|image|size:100', {
        name: 'Company Logo Image',
      }).then(result => {
        if (result.valid) {
          const fileReader = new FileReader();

          fileReader.onload = (event) => {
            this.previewLogoImg = event.target.result;
          }
          fileReader.readAsDataURL(event.target.files[0]);
        } else {
          this.previewLogoImg = '';
        }
      });
    },

    onComBackChange(event) {
      validate(event.target.files[0], 'required|image|size:100', {
        name: 'Company Background Image',
      }).then(result => {
        if (result.valid) {
          const fileReader = new FileReader();

          fileReader.onload = (event) => {
            this.previewBackImg = event.target.result;
          }
          fileReader.readAsDataURL(event.target.files[0]);
        } else {
          this.previewBackImg = '';
        }
      });
    },
    async getCurrentCountry() {
      var newCountrylocation = [];
      axios.get("https://api.ipify.org?format=json")
        .then(response => {
          const ipAddress = response.data.ip;

          axios.get(`https://ipapi.co/${ipAddress}/json/`)
            .then(response => {
              // this.isLoading = false;
              this.newCountrylocation = response.data.country_name;
              // console.log(response.data);
              db.collection("countries")
                .where("country", "==", this.newCountrylocation)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    // console.log(doc);
                    this.country.push({
                      id: doc.id,
                      name: doc.data().country,
                    });
                  });
                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });
            })
            .catch(error => {
              // this.isLoading = false;
              this.error = error.message;
            });
        })
        .catch(error => {
          // this.isLoading = false;
          this.error = error.message;
        });
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.main {
  padding: 45px 50px;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.map-holder {
  width: 65%;
}

#map {
  height: 70vh;
}

.dislpay-arena {
  background: #ffffff;
  box-shadow: 0px -3px 10px rgba(0, 58, 78, 0.1);
  border-radius: 5px;
  padding: 20px 30px;
  width: 25%;
}

.coordinates-header {
  margin-bottom: 50px;
}

.coordinates-header h3 {
  color: #1f2a53;
  font-weight: 600;
}

.coordinates-header p {
  color: rgba(13, 16, 27, 0.75);
  font-weight: 600;
  font-size: 0.875rem;
}

.form-group {
  position: relative;
}

#bb {
  background-color: #3cbba6;
  /* border: 2px solid #03e1bc; */
  color: #edf1f5;
  padding: 5px 6px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  /* margin: 10px 30px; */
  cursor: pointer;
  border-radius: 50px;
}

.location-control {
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(31, 42, 83, 0.25);
  box-shadow: 0px 0px 10px rgba(73, 165, 198, 0.1);
  border-radius: 4px;
  padding: 0px 10px;
  width: 90%;
}

.location-control:focus {
  outline: none;
}

.location-btn {
  margin-top: 15px;
  padding: 10px 15px;
  background: #d80739;
  box-shadow: 0px 4px 10px rgba(73, 165, 198, 0.1);
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
}

.location-btn:focus {
  outline: none;
}

.disabled {
  background: #db7990;
  cursor: not-allowed;
}

.copy-btn {
  background: #f4f6f8 0% 0% no-repeat padding-box;
  border: 1px solid #f4f6f8;
  border-radius: 0px 3px 3px 0px;
  position: absolute;
  color: #5171ef;
  font-size: 0.875rem;
  font-weight: 500;
  height: 30px;
  padding: 0px 10px;
  cursor: pointer;
  right: 3.5%;
  top: 5%;
}

.copy-btn:focus {
  outline: none;
}

.dislpay-arena[data-v-0d798840] {
  width: 30%;
}

.coordinates-header h3[data-v-0d798840] {
  font-size: 20px;
}
</style>
